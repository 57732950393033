.tpl-imageWithText-block .text-block {
  position: absolute;
  left: 50%;
  margin-left: -48px;
  top: 50%;
  margin-top: -18px;
}
.left-text-block {
  text-align: right;
  position: absolute;
  top: 50%;
  margin-top: -76px;
  right: 0;
}
.right-text-block {
  text-align: left;
  position: absolute;
  top: 50%;
  margin-top: -76px;
}
