@font-face {
  font-family: 'Proxima Nova Light';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/proximanova-light-webfont.eot) format('eot'), */ url(./fonts/proximanova-light-webfont.woff2)
      format('woff2'),
    url(./fonts/proximanova-light-webfont.woff) format('woff'),
    url(./fonts/proximanova-light-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/proximanova-bold-webfont.eot) format('eot'), */ url(./fonts/proximanova-bold-webfont.woff2)
      format('woff2'),
    url(./fonts/proximanova-bold-webfont.woff) format('woff'),
    url(./fonts/proximanova-bold-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/proximanova-regular-webfont.eot) format('eot'), */ url(./fonts/proximanova-regular-webfont.woff2)
      format('woff2'),
    url(./fonts/proximanova-regular-webfont.woff) format('woff'),
    url(./fonts/proximanova-regular-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Ex Cn';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/proximanovaexcn-light-webfont.eot) format('eot'), */ url(./fonts/proximanovaexcn-light-webfont.woff2)
      format('woff2'),
    url(./fonts/proximanovaexcn-light-webfont.woff) format('woff'),
    url(./fonts/proximanovaexcn-light-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Ex Cn Thin';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/ProximaNovaExCn-Thin.eot) format('eot'), */ url(./fonts/ProximaNovaExCn-Thin.woff2)
      format('woff2'),
    url(./fonts/ProximaNovaExCn-Thin.woff) format('woff'),
    url(./fonts/ProximaNovaExCn-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Freight Big Light Italic';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/FreightBigLightItalic.eot) format('eot'), */ url(./fonts/FreightBigLightItalic.woff2)
      format('woff2'),
    url(./fonts/FreightBigLightItalic.woff) format('woff'),
    url(./fonts/FreightBigLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Freight Big Book Regular';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/freightbigbook-webfont.eot) format('eot'), */ url(./fonts/freightbigbook-webfont.woff2)
      format('woff2'),
    url(./fonts/freightbigbook-webfont.woff) format('woff'),
    url(./fonts/freightbigbook-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Didot Lt Pro Roman';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/DidotLTPro-Roman.eot) format('eot'), */ url(./fonts/DidotLTPro-Roman.woff2)
      format('woff2'),
    url(./fonts/DidotLTPro-Roman.woff) format('woff'),
    url(./fonts/DidotLTPro-Roman.ttf) format('truetype');
}

@font-face {
  font-family: 'Didot Lt Std Italic';
  font-style: normal;
  font-weight: 400;
  src: /* url(./fonts/DidotLTStd-Italic.eot) format('eot'), */ url(./fonts/DidotLTStd-Italic.woff2)
      format('woff2'),
    url(./fonts/DidotLTStd-Italic.woff) format('woff'),
    url(./fonts/DidotLTStd-Italic.ttf) format('truetype');
}

body.proxima-nova-light .product-title {
  font-family: 'Proxima Nova Light';
  font-style: normal;
  font-weight: 400;
}
.proxima-nova-light {
  font-family: 'Proxima Nova Light';
  font-style: normal;
  font-weight: 400;
}
.proxima-nova-bold {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 400;
}
.proxima-nova {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
}
