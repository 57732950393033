.btn-primary {
  background-color: #42abc8;
  border-color: #42abc8;
  color: #fff;
}
.btn-primary:hover {
  background-color: #89c4d4;
  border-color: #89c4d4;
}

.btn-outline-primary {
  background: transparent;
  border-color: #42abc8;
  color: #42abc8;
}
.btn-outline-primary:hover {
  background-color: #42abc8;
  border-color: #42abc8;
  color: #fff;
}

button {
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
}

button i.center {
  font-size: 23px !important;
  padding-top: 2px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  margin-top: -1px;
}
