/* margin-left */
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-35 {
  margin: 35px !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-2 {
  margin-left: 2px !important;
}
.ml-3 {
  margin-left: 3px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}

/* margin-right */
.mr-0 {
  margin-right: 0 !important;
}
.mr-2 {
  margin-right: 2px !important;
}
.mr-3 {
  margin-right: 3px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-35 {
  margin-right: 35px !important;
}

/* margin-top */
.mt-0 {
  margin-top: 0 !important;
}
.mt-2 {
  margin-top: 2px !important;
}
.mt-3 {
  margin-top: 3px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}

/* margin-bottom */
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.mb-3 {
  margin-bottom: 3px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}

/* padding-left */
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-35 {
  padding: 35px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-2 {
  padding-left: 2px !important;
}
.pl-3 {
  padding-left: 3px !important;
}
.pl-4 {
  padding-left: 4px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-35 {
  padding-left: 35px !important;
}

/* padding-right */
.pr-0 {
  padding-right: 0 !important;
}
.pr-2 {
  padding-right: 2px !important;
}
.pr-3 {
  padding-right: 3px !important;
}
.pr-4 {
  padding-right: 4px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-35 {
  padding-right: 35px !important;
}

/* padding-top */
.pt-0 {
  padding-top: 0 !important;
}
.pt-2 {
  padding-top: 2px !important;
}
.pt-3 {
  padding-top: 3px !important;
}
.pt-4 {
  padding-top: 4px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}

/* padding-bottom */
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-2 {
  padding-bottom: 2px !important;
}
.pb-3 {
  padding-bottom: 3px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
