@import url('font.css');
@import url('button.css');
/* @import url('margin-padding.css'); */

.table {
  width: 100%;
  margin-bottom: 0;
  color: #212529;
}

.table td,
.table th {
  padding: 0;
  vertical-align: top;
  border-top: none;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.list-none {
  list-style: none !important;
}

.width-200 {
  width: 200px;
}

.template-according-li-link {
  color: #444;
}
.template-according-li-link:hover {
  text-decoration: none;
}
.color-orange {
  color: #e28a07;
}
/* TEMPLATE CSS */
.text-verticle-center {
  position: absolute;
  top: 29%;
  margin-top: -45px;
}
.height-100px {
  height: 100px !important;
}
.height-150px {
  height: 150px !important;
}
.height-200px {
  height: 200px !important;
}
.height-300px {
  height: 300px !important;
}
.height-400px {
  height: 400px !important;
}
.height-500px {
  height: 500px !important;
}
/* ./ TEMPLATE CSS */

.footer-container .card {
  background: transparent;
  border: none;
}
.editor-content ul,
.editor-content p {
  margin: 0;
  padding: 0;
}

.product-cart-left,
.product-wish-right {
  background: rgb(218 229 239);
  color: #fff;
  width: 20%;
  text-align: center;
  padding: 3px;
}
.product-cart-left {
  position: absolute;
  bottom: 0;
  border-radius: 0 0 0 3px;
  right: 50px;
  width: 45px;
}

.product-wish-right {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0 3px 0;
  width: 45px;
}

.product-cart-left:hover,
.product-wish-right:hover {
  background: rgb(110 128 144);
}
.pro-sidebar {
  color: #443e3e;
}
.pro-sidebar > .pro-sidebar-inner {
  background: transparent !important;
}

.image-gallery-thumbnail {
  background: #f5f6f9;
  line-height: 100px;
  height: 100px;
}
.image-gallery-thumbnail img {
  max-width: 100%;
  max-height: 88px;
  margin-top: -11px;
}
.hide { 
  display: none;
}
.bg-none {
  background: none !important;
}
.input-group .btn {
    border-color: #ccc !important;
}
.input-group .prepend {
    border-right: none;
    border-radius: 5px 0 0 5px;
}
.input-group .append {
    border-left: none;
    border-radius: 0 5px 5px 0;
}
.btn-default {
  background-color: #efefef;
  border-color: #dfdfdf;
  color: #4c4b4b;
}

.btn-default.active {
  background: #4e4e4e;
  color: #fff;
}